import React, { useState, useMemo} from 'react';
import { AgGridReact } from '@ag-grid-community/react'; // React Grid Logic

import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import "@ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS

import { ModuleRegistry } from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

ModuleRegistry.registerModules([ ServerSideRowModelModule,  ]);

const App = () => {

 // Each Column Definition results in one Column.
 const [columnDefs, setColumnDefs] = useState([
   {field: 'firstName'},
   {field: 'lastName'},
   {field: 'mktgParams.utm_source', headerName: 'Lead Source'},
   {field: 'mktgParams.clickid', headerName: 'Lead ID'},
   {field: 'bind', headerName: 'Bind', cellRenderer: 'agCheckboxCellRenderer', cellRendererParams: {disabled: false}},
   {field: 'quotesViewed', headerName: 'Quotes Viewed', cellRenderer: 'agCheckboxCellRenderer', cellRendererParams: {disabled: false}},
   {field: 'createdAt', headerName: 'Date', filter: 'agDateColumnFilter', filterParams: {
    filterOptions: ['inRange'],
    buttons: ['apply', 'clear'],
    maxNumConditions: 1,
   }, valueGetter: (params) => { return new Date(params.data.createdAt)}},
   {field: 'bpReferenceId'},
   {field: 'email'},
   {field: 'phone'},
   {field: 'businessName'},
   {field: 'businessStructure'},
   {field: 'businessState'},
   {field: 'businessCity'},
   {field: 'businessAddress'},
   {field: 'businessZip'},
   {field: 'coverageNeeded'},
   {field: 'jobType'},
 ]);

 // DefaultColDef sets props common to all Columns
 const defaultColDef = useMemo( ()=> ({
     sortable: false,
     resizable: true,
     filter: false
   }));

 const updateUser = async(userId, field, value) => {
  const reqObj = {};
  reqObj[field] = value;
  await fetch(`https://api.insurepro.com/users/${userId}`, {
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(reqObj)
  });
 }

 const createDatasource = () => {
  return {
    // called by the grid when more rows are required
    getRows: async(params) => {
      console.log(params.request.filterModel)
      let from = new Date();
      let firstDay = new Date(from.getFullYear(), from.getMonth(), 1);
      from = firstDay;
      let to = new Date();
      if(params.request.filterModel && params.request.filterModel.createdAt) {
        from = params.request.filterModel.createdAt.dateFrom;
        to = params.request.filterModel.createdAt.dateTo;
      }
      // get data for request from server
      const response = await fetch(`https://api.insurepro.com/users/all?start=${from}&end=${to}&skip=${params.request.startRow}&limit=100`);
      const resp = await response.json();
      if (resp) {
        // supply rows for requested block to grid
        params.success({
          rowData: resp
        });
      } else {
        // inform grid request failed
        params.fail();
      }
    }
  };
}

 return (
   <div>
     {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
     <div className="ag-theme-quartz" style={{height: '100vh'}}>
       <AgGridReact columnDefs={columnDefs} defaultColDef={defaultColDef} rowModelType='serverSide' serverSideDatasource={createDatasource()}
        gridOptions={{ensureDomOrder: true, enableCellTextSelection: true}} onCellValueChanged={(e) => {
          updateUser(e.data._id, e.colDef.field, e.newValue);
       }}/>
     </div>
   </div>
 );
};

export default App;
